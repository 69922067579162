<template>
  <div>
    <v-textarea
      outlined
      :disabled="disabled"
      dense
      auto-grow
      v-model="data"
      @input="$emit('input', data)"
      style="border-radius: 16px; min-height: 200px"
    ></v-textarea>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    data: ''
  }),
  watch: {
    value: function (value) {
      this.data = value;
    }
  },
  created() {
    this.data = this.value;
  }
};
</script>
<style lang="scss">
.v-input {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.0024em;
}
.v-textarea.v-textarea--solo {
  border-radius: 16px;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}
</style>
